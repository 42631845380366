<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-05-28 09:59:27
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\county.vue
-->
<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="top">
          <div class="title">
            <span class="titleText">销售数据总览</span>
            <div class="titleBox">
              <div class="box">
                <div class="img"><img src="../assets/images/icon／销售量@2x.png" alt=""></div>
                <div class="text">
                  <span>{{salesCount.totalVolume}}</span>万斤
                  <div class="w">销售量</div>
                </div>
              </div>
              <div class="box">
                <div class="img"><img src="../assets/images/icon／销售额@2x.png" alt=""></div>
                <div class="text">
                  <span>{{salesCount.totalAmount}}</span>万元
                  <div class="w">销售额</div>
                </div>
              </div>
              <div class="box">
                <div class="img"><img src="../assets/images/yimutianzizheng@2x.png" alt=""></div>
                <div class="text">
                  <span>{{salesCount.distribute}}</span>万元
                  <div class="w">已分配收入</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pieBox">
            <title2 name="销售总额构成"></title2>
            <div class="pie" ref="pie"></div>
            <div class="pieList" v-for="(item, index) in pieData" :key="index">
              <div class="text">
                <div :class="'dian' + index"  />
                {{item.name}}
              </div>
              <div class="money">{{item.value}} 万元</div>
            </div>
          </div>
          <div class="gardenBox">
            <title2 name="全市各镇、街道销售总额构成情况"></title2>

          <ul class="garden-title">
            <li>
              <span>镇、街道</span>
              <span>自主销售</span>
              <span>爱心认购</span>
              <span>兜底销售</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body income">
            <vue-seamless-scroll
              :data="gardenInfoCountVOTowns"
              :class-option="defaultOption"
            >
              <li
                v-for="item in gardenInfoCountVOTowns"
                :key="item.orgId"
                @click="goGarden(item.orgId)"
              >
                <span>{{ item.town }}</span>
                <span>{{ item.sales.saleAmount }}万元</span>
                <span>{{ item.sales.subAmount }}万元</span>
                <span>{{ item.sales.underpinAmount }}万元</span>
              </li>
            </vue-seamless-scroll>
          </ul>
          </div>
        </div>
        <div class="bottom">
          <title2 name="各菜园销售/分配情况" style="margin: 20px 0;"></title2>
          <div class="shellBox">
          <div class="shell">
            <vue-seamless-scroll :data="gardenInfoCountVOGardens" :class-option="defaultOption2">
              <div class="vegetable">
                <div class="box" v-for="item in gardenInfoCountVOGardens" :key="item.id">
                  <div class="title">{{item.gardenName}}</div>
                  <div class="heng" />
                  <div class="lists">
                    <div class="quantity">
                      <span class="text">销售量</span>
                      <span>{{item.sales.totalVolume}}万斤</span>
                    </div>
                    <div class="strips">
                      <div class="strip">
                        <span>{{item.sales.totalAmount}}万元</span>
                        <div class="img">
                          <img src="../assets/images/菜园营销／菜园销售分配数据／bg@2x.png" alt="">
                          <div class="percentage" :style="clip(item.sales.totalAmount, 1)"><img src="../assets/images/菜园营销／菜园销售分配数据／销售总额@2x.png" alt=""></div>
                          
                        </div>
                        <span>销售总额</span>
                      </div>
                      <div class="strip">
                        <span>{{item.sales.distribute}}万元</span>
                        <div class="img">
                          <img src="../assets/images/菜园营销／菜园销售分配数据／bg@2x.png" alt="">
                          <div class="percentage" :style="clip(item.sales.distribute, 2)"><img src="../assets/images/菜园营销／菜园销售分配数据／已分配收入@2x.png" alt=""></div>
                          
                        </div>
                        <span>已分配收入</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "county",
  data() {
    return {
      loading: true,
      barData: {},
      pieData: [],
      townList: [],
      gardenList: [],
      titleData: {
        totalHouse: 0,
        distributeAmountYears: 0,
        averageIncome : 0
      },
      gardenInfoCountVOTowns: [],
      gardenInfoCountVOGardens: [],
      salesCount: {},
    };
  },
  computed: {
    optionPie() {
      return {
        tooltip: {
          trigger: "item",
        },
        grid: {
          // 让图表占满容器
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },

        series: [
          {
            name: "已分配收入构成",
            type: "pie",
            radius: ["50%", "75%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              formatter: function (params) {
                return params.percent + "%";
              },
              // offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
              fontSize: 12,
              textStyle: {
                color: "#ffffff",
              },
            },
            labelLine: {
              show: true,
            },

            data: this.pieData,
          },
        ],
      };
    },
    defaultOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    defaultOption2() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      // 图形数据处理
      this.$api.bigdata.marketing("330481").then((res) => {
        this.gardenInfoCountVOTowns = res.data.gardenInfoCountVOTowns
        this.gardenInfoCountVOGardens = res.data.gardenInfoCountVOGardens
        this.salesCount = res.data.salesCount
        const data = [
          {
            name: '爱心认购',
            value: res.data.salesCount.subAmount,
          },
          {
            name: '自主销售',
            value: res.data.salesCount.saleAmount,
          },
          {
            name: '兜底销售',
            value: res.data.salesCount.underpinAmount,
          }
        ]
        this.pieData = data
        // 柱状图
        const pie = this.$echarts.init(this.$refs.pie);
        pie.setOption(this.optionPie);
        this.loading = false
      });
    },
    clip(data, type) {
      if (type === 2) {
        const height = (1 - data/this.salesCount.distribute)*138
        return `clip:rect(${height}px,60px,138px,0)`
      } else if (type === 1) {
        const height = (1 - data/this.salesCount.totalAmount)*138
        return `clip:rect(${height}px,60px,138px,0)`
      }
      
      
    },
  },
};
</script>

<style scoped lang="scss">
.pie {
  width: 100%;
  height: 300px;
  padding: 30px 0 40px;
  position: relative;
  top: 10px;
}
.pieList {
  display: flex;
  justify-content: space-between;
  padding: 4px 75px;
  
  .text {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    div {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      opacity: 1;
      margin-right: 5px;
      letter-spacing: 1px;
    }
    .dian0 {
      background: #5470c6;
    }
    .dian1 {
      background: #91cc75;
    }
    .dian2 {
      background: #fac858;
    }
    .dian3 {
      background: #ee6666;
    }
    .dian4 {
      background: #73c0de;
    }
  }
  .money {
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
}
.shellBox {
  background: url("../assets/images/菜园建设／各菜园销售列表／bg@2x.png") no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 8px 8px;
}
.shell {
  width: 1748px;
  height: 428px;
  overflow: hidden;
}
.vegetable {
  width: 100%;
  height: 428px;
  display: flex;
  align-items: center;
  .box {
    width: 302px;
    height: 341px;
    background: url("../assets/images/菜园建设／各菜园销售卡片／bg@2x.png") no-repeat;
    background-size: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    .title {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
    }
    .heng {
      width: 16px;
      height: 4px;
      background: #00aaff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 8px 0 16px;
    }
    .lists {
      .quantity {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        letter-spacing: 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;
        margin-top: 10px;
        padding: 0 20px;
        .text {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          letter-spacing: 1px;
        }
      }
      .strips {
        display: flex;
        justify-content: center;
        .strip {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          letter-spacing: 1px;
          margin:  0 25px;
          .img {
            width: 60px;
            height: 138px;
            margin: 8px 0;
            position: relative;
            z-index: 0;
            .percentage {
              position: absolute;
              top: 0;
              left: 0;
              width: 60px;
              height: 138px;
              overflow: hidden;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
.content {
  padding: 24px 46px;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    .title {
      background: url('../assets/images/sZgyqU941_1614742132018__SY5xOwKet (1)@2x.png') no-repeat;
      background-size: 100%;
      width: 239px;
      height: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 25px;
      .titleText {
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin-bottom: 48px;
      }
      .titleBox {
        display: flex;
        flex-direction: column;
        .box {
          display: flex;
          align-items: center;
          .img {
            background: url('../assets/images/位图@2x.png') no-repeat;
            background-size: 100%;
            width: 102px;
            height: 102px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .text {
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #EEF8FF;
            span {
              font-size: 22px;
              font-family: DIN Medium-Regular, DIN Medium;
              font-weight: 400;
              color: #FEC700;
              letter-spacing: 1px;
            }
            .w {
              padding-top: 10px;
            }
          }
        }
      }
    }
    .pieBox {
      width: 500px;
      height: 450px;
      margin: 0 42px 0 29px;
    }
    .gardenBox {
      width: 1027px;
      height: 450px;
      .garden-title {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          display: flex;
          span {
            letter-spacing: 1px;
            display: inline-block;
            font-size: 16px;
            padding-left: 20px;
            line-height: 46px;
            flex: 1;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
      .garden-list {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          height: 345px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          background: url("../assets/images/菜园建设／农技、农资、种植概况／bg@2x.png")
            no-repeat;
          background-size: 100%;
          display: flex;
          margin-bottom: 10px;
          span {
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            letter-spacing: 1px;
            display: inline-block;
            flex: 1;
            font-size: 16px;
            padding-left: 20px;
            padding-top: 3px;
            line-height: 46px;
            color: #ffffff;
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
    }
  }
}
</style>